<template>
  <div class="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal">
    <!--Console Content-->

    <div v-if="userData.agent" class="grid grid-cols-2">
      <div class="w-full p-3" v-if="userData.type == 'admin'">
        <!--Metric Card-->

        <div class="bg-white border rounded shadow p-2">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="rounded p-3 bg-green-600">
                <box-icon
                  color="#fff"
                  size="30px"
                  type="solid"
                  name="wallet"
                ></box-icon>
              </div>
            </div>
            <div class="flex-1 text-right md:text-center">
              <h5 class="font-bold uppercase text-gray-500">Account Balance</h5>
              <h3 class="font-bold text-3xl">
                D{{ userData.agent.actualbalance }}
                <span class="text-green-500"
                  ><i class="fas fa-caret-up"></i
                ></span>
              </h3>
            </div>
          </div>
        </div>

        <!--/Metric Card-->
      </div>
      <div class="w-full p-3"  v-if="userData.type == 'admin'">
        <!--Metric Card-->
        <div class="bg-white border rounded shadow p-2">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="rounded p-3 bg-blue-600">
                <i class="fas fa-users fa-2x fa-fw fa-inverse"></i>
              </div>
            </div>
            <div class="flex-1 text-right md:text-center">
              <h5 class="font-bold uppercase text-gray-500">
                Monthly Commission
              </h5>
              <h3 class="font-bold text-3xl">
                D{{
                 userData.today_sales.commission
                }}
                <span class="text-pink-500"
                  ><i class="fas fa-exchange-alt"></i
                ></span>
              </h3>
            </div>
          </div>
        </div>
        <!--/Metric Card-->
      </div>
      <div class="w-full p-3">
        <!--Metric Card-->
        <div class="bg-white border rounded shadow p-2">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="rounded p-3 bg-blue-600">
                <i class="fas fa-user-plus fa-2x fa-fw fa-inverse"></i>
              </div>
            </div>
            <div  v-if="userData.type == 'admin'" class="flex-1 text-right md:text-center">
              <h5 class="font-bold uppercase text-gray-500">Today's Sales</h5>
              <h3 class="font-bold text-3xl">
                D{{ userData.today_sales.todays_sales }}
                <span class="text-yellow-600"
                  ><i class="fas fa-caret-up"></i
                ></span>
              </h3>
            </div>

            <div   v-if="userData.type != 'admin'"  class="flex-1 text-right md:text-center">
              <h5 class="font-bold uppercase text-gray-500">Today's Sales</h5>
              <h3 class="font-bold text-3xl">
                D{{ userData.today_sales.sales }}
                <span class="text-yellow-600"
                  ><i class="fas fa-caret-up"></i
                ></span>
              </h3>
            </div>
          </div>
        </div>
        <!--/Metric Card-->
      </div>

      <div v-if="userData.type == 'admin'" class="w-full p-3">
        <!--Metric Card-->
        <div class="bg-white border rounded shadow p-2">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="rounded p-3 bg-red-600">
                <i class="fas fa-user-plus fa-2x fa-fw fa-inverse"></i>
              </div>
            </div>
            <div class="flex-1 text-right md:text-center">
              <h5 class="font-bold uppercase text-gray-500">
                Todays Commission
              </h5>
              <h3 class="font-bold text-3xl">
                D{{ userData.today_sales.todays_commission }}
                <span class="text-yellow-600"
                  ><i class="fas fa-caret-up"></i
                ></span>
              </h3>
            </div>
          </div>
        </div>
        <!--/Metric Card-->
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

import FormatPrice from "@/mixins/FormatPrice";
import axios from "axios";

export default {
  middleware: "auth",

  mixins: [
    // AgentsBalance,
    // VendorInfo,
    // TxAgentsBalance,
    FormatPrice,
    // checkPermissionExist,
  ],

  layout: "default",

  computed: mapGetters({
    user: "auth/user",
  }),

  components: {},

  data: () => ({
    userData: [],
    accounts: [],
    stats: null,
    branchSales: [],
    onlineUseres: 0,
    agentsSales: null,

    filterDate: "today",

    results: [
      { name: "Sales", values: [] },
      { name: "Commission", values: [] },
    ],
    labels: [],

    pendingWorksheets: [],
  }),

  created() {},

  mounted() {
    // if (!this.checkPermissionExist(this.user.data, "dashboard-read")) {
    //   this.$router.push({ path: `/myprofile` });
    //   return false;
    // } else {
    // }
    this.fetchUser();
  },

  methods: {
    async fetchUser() {
      const { data } = await axios.get(`/agent/profile`);
      this.userData = data.data;
      console.log(this.userData);
    },
  },
};
</script>

<style>
.bg-main {
  background-color: #343c4e;
  border-radius: 10px;
  color: white;
  font-size: 20px;
}
</style>
